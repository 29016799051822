import useSWRImmutable from "swr/immutable";
import { DetermineApiUri } from "../../../../../config";
import { useProtectedFetch } from "../../../../hooks/useProtectedFetch";
import { LoadingComponent } from "../../../../components/LoadingComponent";

/**
 * Kladblok image component
 */
export function KladblokImage({ incidentNr, fileGuid }: Readonly<{ incidentNr: string, fileGuid: string }>) {
  const resourceFetch = useProtectedFetch("blobUrl");
  const { data: imgUrl } = useSWRImmutable<string>(`${DetermineApiUri()}/api/oefenincident/${incidentNr}/kladblok/image/${fileGuid}`, resourceFetch);

  const style = {
    maxWidth: "100%",
    maxHeight: "700px"
  };
  if (!imgUrl)
    return <LoadingComponent size="small" />;

  return (
    <img src={imgUrl} alt="Kladblokregel" style={style} />
  );
}