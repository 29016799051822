import moment from "moment";
import { Incident } from "../../models/Incident";
import { DisciplineIndicator } from "../DisciplineIndicator";
import { ReactivateButton } from "../IconButtons/ReactivateButton";
import { StopButton } from "../IconButtons/StopButton";
import style from "./style.module.scss";

type Props = {
  incident: Incident;
  isDisabled: boolean;
  onDisable?: (incidentId: number) => void;
  onReactivate?: (incidentId: number) => void;
};

export function IncidentRowView({ incident, isDisabled, onDisable, onReactivate }: Readonly<Props>) {
  const hasPolitie = incident.indDiscIncident?.includes("P") ?? false;
  const hasBrandweer = incident.indDiscIncident?.includes("B") ?? true;
  const hasAmbulance = incident.indDiscIncident?.includes("A") ?? false;

  return (
    <div className={style.record}>
      <DisciplineIndicator politie={hasPolitie} brandweer={hasBrandweer} ambulance={hasAmbulance} />
      <div className={style.descriptionWrapper}>
        <div className={style.description}>
          <span className={style.incidentNr}>{incident.nrIncident}</span>
          <span className={`${style.tag} ${incident.dtgEindeIncident ? style.gesloten : ""}`}>{incident.dtgEindeIncident ? "Gesloten" : "Actief"}</span>
          <span className={style.startDate}>{moment(incident.brwDtgStartIncident).format("DD-MM-YYYY HH:mm")}</span>
        </div>
        <div className={style.locationWrapper}>
          <span className={style.brwMelding}>{incident.brwMeldingCl} - {incident.brwMeldingCl1} - {incident.brwMeldingCl2}</span>
          <span className={style.location}>
            <span>{incident.plaatsNaam},</span>
            <span>{incident.naamLocatie1},</span>
            <span>{incident.huisPaalNr}</span>
            <span>{incident.huisNrToev}</span>
            <span>{incident.huisNrAanduiding}</span>
            <span>{incident.huisLetter}</span>
          </span>
        </div>
      </div>
      <div className={style.vehicleCodes}>
        {incident.inzetEenheden?.map((eenheid) => (
          <div key={eenheid.inzetEenheidId} className={style.vehicleCode}>
            {eenheid.roepnaamEenheid}
          </div>
        ))}
      </div>
      <div className={style.actions}>
        {(!isDisabled && onDisable) && <StopButton onClick={() => onDisable(incident.incidentId)}></StopButton>}
        {(isDisabled && onReactivate) && <ReactivateButton onClick={() => onReactivate(incident.incidentId)}></ReactivateButton>}
      </div>
    </div>
  );
}
