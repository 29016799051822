import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { LoadingComponent } from "../LoadingComponent";

/**
 * A button that represents the meldkamer
 * @param onClick - The function to call when the button is clicked
 */
export function SendButton({ onClick, isLoading }: Readonly<{ onClick: () => void, isLoading?: boolean }>) {
  return (
    <button className={style.sendButton} onClick={onClick}>
      {isLoading && <LoadingComponent color="white" size="small" />}
      {!isLoading && <FontAwesomeIcon icon={faPaperPlane} />}
    </button>
  );
}