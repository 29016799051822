import { useEffect, useRef, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

type Props = {
  label?: string | undefined;
  value?: Date | undefined;
  placeholder?: string | undefined;
  min?: Date | undefined;
  max?: Date | undefined;
  onValueChange: (val: Date | undefined) => void;
  tabIndex?: number;
  required?: boolean;
};

export function Datepicker({
  label,
  value,
  placeholder,
  onValueChange,
  min,
  max,
  tabIndex,
  required
}: Props) {
  const inputDateRef = useRef<HTMLInputElement>(null);

  function dateToString(date?: Date): string {
    if (!date || isNaN(+date)) {
      return "";
    }

    // This works because sweden uses the required yyyy-mm-dd format for input type datetime-local (see below).
    const convertedDate = date.toLocaleString("sv");
    return convertedDate.substring(0, convertedDate.length - 3);
  }

  return (
    <>
      <div tabIndex={-1} className="input-date-wrapper">
        {label && <label tabIndex={-1}>{label}</label>}
        <div
          className={"input-wrapper"}
          onClick={() => inputDateRef.current?.showPicker()}
        >
          <input
            type="datetime-local"
            title={label}
            required={required ?? false}
            ref={inputDateRef}
            min={min ? dateToString(min) : undefined}
            max={max ? dateToString(max) : undefined}
            tabIndex={tabIndex ?? 0}
            value={dateToString(value)}
            placeholder={placeholder}
            name={label}
            onChange={(event) => onValueChange(new Date(event.target.value))}
          />
          <FontAwesomeIcon icon={faCalendarDays} />
        </div>
      </div>
    </>
  );
}
