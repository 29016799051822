import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { backendRequest, loginRequest } from "../../config";

export class ResourceFetchError extends Error {
  constructor(message: string, status: number, response: Response) {
    super(message);
    this.name = "ResourceFetchError";
    this.status = status;
    this.response = response;
  }
  status: number;
  response: Response;
}

type responseType = "json" | "blob" | "blobUrl" | "none";

/**
 *  Custom hook that fetches a resource from the backend. The resource is fetched with an access token from the MSAL cache
 * @param responseType Indicates what type of response is expected from the fetch call
 * @returns
 */
export function useProtectedFetch(responseType: responseType = "json") {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  return async (input: RequestInfo, init?: RequestInit) => {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest);
      throw new Error("User is not authenticated.");
    }

    const token = await instance.acquireTokenSilent(backendRequest);
    const headers = {
      ...init?.headers,
      "Authorization": `Bearer ${token.accessToken}`
    };

    const response = await fetch(input, { ...init, headers });

    console.log("Response", response);

    if (!response.ok) {
      let errorMessage = "Er is iets fout gegaan op de backend... Probeer het later nog een keer of neem contact op met de beheerder.";

      if (response.status == 400) {
        const badRequestMessage = await response.text();

        if (badRequestMessage) {
          errorMessage = badRequestMessage;
        }
      }

      throw new ResourceFetchError(errorMessage, response.status, response);
    }

    switch (responseType) {
      case "json":
        return response.json();
      case "blob":
        return response.blob();
      case "blobUrl":
        return window.URL.createObjectURL(await response.blob());
      case "none":
        return response;
      default:
        throw new Error("Invalid response type");
    }
  };
}