export interface SystemState {
  replica: SystemStateEnum;
  incidentFacade: SystemStateEnum;
}

export enum SystemStateEnum {
  up = 1,
  warning = 2,
  error = 3,
}
