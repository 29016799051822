import { DetermineApiUri } from "../../config";
import useSWR from "swr";
import { SystemLogRecord } from "../models/SystemLogRecord";
import { useProtectedFetch } from "./useProtectedFetch";

export function useFacadeLogs() {
  const { data, error } = useSWR<SystemLogRecord[]>(
    `${DetermineApiUri()}/api/log/IncidentFacadeLogRecords/10`,
    useProtectedFetch(),
    { refreshInterval: 5000 }
  );

  return {
    facadeLogs: data,
    isLoading: !error && !data,
    isError: error,
  };
}
