import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import protectedRoutes from "./routes";
import { useUserRole } from "./hooks/useUserRole";
import { ROLE } from "./enums/role";
import ProtectedRoute from "./models/ProtectedRoute";

const App = () => {
  const userRole = useUserRole();
  return (<>
    <Navbar />
    <Routes>
      {protectedRoutes.filter(x => x.allowedRoles ? x.allowedRoles.includes(userRole as ROLE) : true).map((route) => {
        return (
          <Route key={route.id} path={route.path} element={route.element}>
            {flattenChildren(route.children ?? []).map((childRoute) => {
              return <Route key={childRoute.id} index={childRoute.index ?? false} path={childRoute.path} element={childRoute.element} />;
            })}
          </Route>
        );
      })}
    </Routes>
  </>);
};

function flattenChildren(children: ProtectedRoute[]): ProtectedRoute[] {
  return children.map((child) => {
    return [child, ...flattenChildren(child.children ?? [])];
  }).flat(2);
}

export default App;
