import Moment from "moment";
import { SystemLogRecord } from "../../models/SystemLogRecord";
import "./logOverview.scss";

const LogOverview = ({
  logsReplicaImport,
  logsIncidentFacade,
  showLogs,
}: {
  logsReplicaImport: SystemLogRecord[];
  logsIncidentFacade: SystemLogRecord[];
  showLogs: boolean;
}) => (
  <div className="log-overview">
    <div className="table-container">
      <table hidden={!showLogs}>
        <thead>
          <tr>
            <th colSpan={3}>LMS Replica db import functie</th>
          </tr>
          <tr>
            <th>Resultaat</th>
            <th>Import tijdstip</th>
            <th>Duur taak in ms</th>
          </tr>
        </thead>
        <tbody>
          {logsReplicaImport.map((log: SystemLogRecord) => {
            return (
              <tr
                key={log.timestamp.toString()}
                className={log.success ? "success" : "failure"}
              >
                <td>{log.success ? "Ok" : "Fout"}</td>
                <td>
                  {Moment.utc(log.timestamp)
                    ?.local()
                    .format("DD/MM/YYYY, HH:mm:ss")}
                </td>
                <td>{log.durationInMs.toPrecision(5)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>

    <div className="table-container">
      <table hidden={!showLogs}>
        <thead>
          <tr>
            <th colSpan={3}>Incident Facade import service</th>
          </tr>
          <tr>
            <th>Resultaat</th>
            <th>Import tijdstip</th>
            <th>Duur taak in ms</th>
          </tr>
        </thead>
        <tbody>
          {logsIncidentFacade.map((log: SystemLogRecord) => {
            return (
              <tr
                key={log.timestamp.toString()}
                className={log.success ? "success" : "failure"}
              >
                <td>{log.success ? "Ok" : "Fout"}</td>
                <td>
                  {Moment.utc(log.timestamp)
                    ?.local()
                    .format("DD/MM/YYYY, HH:mm:ss")}
                </td>
                <td>{log.durationInMs.toPrecision(5)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
);

export default LogOverview;
