import { Oefenincident } from "../../../models/Oefenincident";
import style from "./style.module.scss";
import useSWR from "swr";
import { DetermineApiUri } from "../../../../config";
import { useProtectedFetch } from "../../../hooks/useProtectedFetch";
import { useEffect, useState } from "react";
import { StopButton } from "../../../components/IconButtons/StopButton";
import { AddButton } from "../../../components/IconButtons/AddButton";
import { useNavigate } from "react-router-dom";
import { EditButton } from "../../../components/IconButtons/EditButton";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { DisciplineIndicator } from "../../../components/DisciplineIndicator";
import { MeldkamerButton } from "../../../components/IconButtons/MeldkamerButton";

export function OefenincidentenOverviewPage() {
  const [pages, setPages] = useState(1);
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const records: JSX.Element[] = [];
  const navigate = useNavigate();

  for (let i = 0; i < pages; i++) {
    records.push(<PaginatedRecords take={10} skip={i * 10} key={i} onEmpty={onEmpty} onReady={onReady} />);
  }

  /**
   * Set hasMoreRecords to false
   */
  function onEmpty() {
    setHasMoreRecords(false);
  }

  function onReady() {
    setIsLoading(false);
  }

  /**
   * Load more records
   */
  function loadMore() {
    setPages(pages + 1);
  }

  function addOefenincident() {
    navigate("/oefenincident/add");
  }

  return (
    <div className={"content-container " + style.contentContainer}>
      <div className={style.header}>
        <h1>Oefenincidenten</h1>
        <AddButton title="Nieuw oefenincident" onClick={addOefenincident} />
      </div>
      <p>
        Hier zie je een overzicht van alle oefenincidenten die zijn aangemaakt.
        Deze oefenincidenten zijn zichtbaar in de OIV-Viewer.
      </p>
      <div className={style.records}>
        {records}
      </div>
      {(hasMoreRecords && !isLoading) && <button onClick={loadMore} className={style.loadMore}>Laad meer...</button>}
    </div>
  );
}

/**
 * Component for paginated oefenincident records
 * @param take Amount of records retrieved
 * @param skip Amount of records to skip
 * @param onEmpty Function to call when there are no more records
 * @returns Record elements
 */
function PaginatedRecords({ take, skip, onEmpty, onReady }: Readonly<{ take: number, skip: number, onEmpty: () => void, onReady: () => void }>) {
  const protectedFetcher = useProtectedFetch();
  const navigate = useNavigate();
  const { data, mutate } = useSWR<Oefenincident[]>(
    `${DetermineApiUri()}/api/oefenincident?take=${take}&skip=${skip}`,
    protectedFetcher,
  );

  async function onRemove(incident: Oefenincident) {
    // set incident.dtgEindeincident to current date utc
    const now = new Date();
    const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
    incident.dtgEindeIncident = utcDate;

    await protectedFetcher(`${DetermineApiUri()}/api/oefenincident`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(incident),
    });
    mutate();
  }

  async function onEdit(incident: Oefenincident) {
    navigate(`/oefenincident/edit/${incident.incidentNr}`);
  }

  async function onMeldkamer(incident: Oefenincident) {
    navigate(`/oefenincident/meldkamer/${incident.incidentNr}`);
  }

  useEffect(() => {
    if (data && (data.length === 0 || data.length < take)) {
      onEmpty();
    }

    if (data) {
      onReady();
    }

  }, [data]);

  if (!data)
    return <LoadingComponent />;

  return (
    <>
      {data?.map((oefenincident) => (
        <div className={`${style.record}  ${oefenincident.dtgEindeIncident ? style.gesloten : ""} `} key={oefenincident.incidentNr}>
          <DisciplineIndicator politie={oefenincident.indDiscIncidentPolitie} brandweer={true} ambulance={oefenincident.indDiscIncidentAmbulance} />
          <div className={"description-wrapper"}>
            <div className={style.description}>
              <span className={style.incidentNr}>{oefenincident.incidentNr}</span>
              <span className={`${style.tag} ${oefenincident.dtgEindeIncident ? style.gesloten : ""}`}>{oefenincident.dtgEindeIncident ? "Gesloten" : "Actief"}</span>
            </div>
            <div className={style.locationWrapper}>
              <span className={style.brwMelding}>{oefenincident.brwMeldingCl} - {oefenincident.brwMeldingCl1} - {oefenincident.brwMeldingCl2}</span>
              <span className={style.location}>
                <span>{oefenincident.plaatsNaam},</span>
                <span>{oefenincident.straatNaam},</span>
                <span>{oefenincident.huisnummer}</span>
              </span>
            </div>
          </div>
          <div className={style.vehicleCodes}>
            {oefenincident.eenheden?.map((eenheid) => (
              <div key={eenheid.inzetEenheidId} className={style.vehicleCode}>
                {eenheid.roepnaamEenheid}
              </div>
            ))}
          </div>
          <div className={style.actions}>
            {!oefenincident.dtgEindeIncident && <MeldkamerButton onClick={() => onMeldkamer(oefenincident)}></MeldkamerButton>}
            {!oefenincident.dtgEindeIncident && <EditButton onClick={() => onEdit(oefenincident)}></EditButton>}
            {!oefenincident.dtgEindeIncident && <StopButton onClick={() => onRemove(oefenincident)}></StopButton>}
          </div>
        </div>
      ))}
    </>
  );
}
