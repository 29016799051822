import IncidentFlow from "../../components/incident-flow";
import IncidentOverview from "../../components/incident-overview";
import { useIncidents } from "../../hooks/useIncidents";
import {LoadingComponent} from "../../components/LoadingComponent";
import {createRef, useEffect, useState} from "react";
import {useInfiniteScroll} from "../../hooks/useInfiniteScroll";
import {Incident} from "../../models/Incident";
import {Pagination} from "../../models/Pagination";

export default function StatusPage() {
  const scrollAnchor = createRef<HTMLDivElement>();
  const [items, setItems] = useState<Incident[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ take: 10, skip: 0,});
  const { incidents, isLoading } = useIncidents(pagination.skip, pagination.take);

  /**
   * When the incidents are loaded, set the items to the incidents
   * @remarks This delay is added to prevent the list from dissapearing when the incidents are not loaded yet.
   */
  useEffect(() => {
    if (!incidents || incidents.length === 0) return;

    setItems(incidents);
  }, [incidents]);

  /**
   * Use infinite scroll to retrieve more incidents
   */
  useInfiniteScroll(scrollAnchor, retrieveMoreIncidents);

  /**
   * Retrieve more incidents
   */
  function retrieveMoreIncidents() {
    setPagination({
      take: pagination.take + 10,
      skip: pagination.skip,
    });
  }

  return (
    <>
      <div className={"content-container"}>
        <IncidentFlow />
      </div>
      <div className={"content-container"}>
        <IncidentOverview incidents={items} />
        {isLoading ? <LoadingComponent/> : <div ref={scrollAnchor}></div>}
      </div>
    </>
  );
}
