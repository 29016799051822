import {RefObject, useEffect, useRef} from "react";

/**
 * Hook for infinite scrolling
 * @param observable IntersectionObserver that will trigger the callback when in view.
 * @param callback Function to call when the observable is in view.
 */
export const useInfiniteScroll = (observable: RefObject<HTMLDivElement>, callback: () => void) => {
  const observer = useRef<IntersectionObserver | null>();

  useEffect(() => {
    if (observer.current || !observable.current) {
      observer.current?.disconnect();
    }

    if(!observable.current) return;

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        callback();
      }
    });

    observer.current?.observe(observable.current as Element);

    return () => {
      if (observer.current)
        observer.current.disconnect();
    };
  }, [callback, observable]);
};
