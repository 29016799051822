import LogOverview from "../../components/log-overview";
import { useReplicaLogs } from "../../hooks/useReplicaLogs";
import { useFacadeLogs } from "../../hooks/useFacadeLogs";

export default function SystemLogsPage() {
  const { replicaLogs } = useReplicaLogs();
  const { facadeLogs } = useFacadeLogs();
  if (!replicaLogs || !facadeLogs) return <div>Loading...</div>;

  return (
    <div className={"content-container"}>
      <LogOverview
        logsReplicaImport={replicaLogs}
        logsIncidentFacade={facadeLogs}
        showLogs={true}
      />
    </div>
  );
}
