import { ChangeEvent } from "react";
import "./style.scss";

type Props = {
  label?: string;
  internalValue?: string | undefined;
  placeholder?: string | undefined;
  maxLength?: number;
  onValueChange?: (val: string) => void;
  disabled?: boolean;
  required?: boolean;
};

export default function TextInput({
  label,
  internalValue,
  onValueChange,
  placeholder,
  maxLength,
  disabled,
  required
}: Props) {
  return (
    <div className="text-input-wrapper">
      {label && <label>{label}</label>}
      <input
        required={ required ?? false}
        type="text"
        placeholder={placeholder}
        value={internalValue}
        disabled={disabled ?? false}
        maxLength={maxLength}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>{
          if(onValueChange)
            onValueChange(event.currentTarget.value);
        }}
      />
    </div>
  );
}
