import { useState } from "react";
import { OivNotification } from "../../../models/Notification";
import { classNames } from "../../../utils/classNames";
import { NotificationEditor } from "../notificationEditor";
import { NotificationView } from "../notificationView/indext";
import "./style.scss";

interface NotificationProps {
  notification: OivNotification;
}

export const Notification = (props: NotificationProps) => {
  const [editMode, setEditMode] = useState(false);
  const { notification } = props;

  return (
    <div
      className={classNames(
        editMode ? "edit-mode" : "view-mode",
        "notification"
      )}
    >
      {editMode ? (
        <NotificationEditor notification={notification} />
      ) : (
        <NotificationView notification={notification} />
      )}
      <button onClick={() => setEditMode(!editMode)}>
        {editMode
          ? "Schakel de bewerkingsmodus uit"
          : "Schakel de bewerkingsmodus in"}
      </button>
    </div>
  );
};
