export function HomePage() {
  return (
    <div className={"content-container"}>
      <h1>OIV-Beheer</h1>
      <p>
        Welkom bij onze beheer tool voor het monitoren van de status, het testen en oefenen van incidenten, en het
        plaatsen van meldingen voor de OIV-Viewer!
      </p>
    </div>
  );
}