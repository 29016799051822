import { useState, useEffect } from "react";
import { useFacadeLogs } from "../../hooks/useFacadeLogs";
import { useReplicaLogs } from "../../hooks/useReplicaLogs";
import { SystemLogRecord } from "../../models/SystemLogRecord";
import { SystemState, SystemStateEnum } from "../../models/SystemState";
import "./IncidentFlow.scss";

const IncidentFlow = () => {
  const { replicaLogs } = useReplicaLogs();
  const { facadeLogs } = useFacadeLogs();
  const [systemState, setSystemState] = useState<SystemState>({
    incidentFacade: SystemStateEnum.up,
    replica: SystemStateEnum.up,
  });

  /**
   * Determine based on the retrieved request logs what the state of the incident import mechanism is.
   * Is fired when the logs collection has changed.
   */
  useEffect(() => {
    if (replicaLogs && facadeLogs)
      setSystemState({
        replica: determineState(replicaLogs),
        incidentFacade: determineState(facadeLogs),
      });
  }, [replicaLogs, facadeLogs]);

  if (!replicaLogs && !facadeLogs) return <div>Loading...</div>;

  const determineState = (logs: SystemLogRecord[]): SystemStateEnum => {
    let state = SystemStateEnum.up;

    if (logs.some((y) => !y.success)) {
      state = SystemStateEnum.warning;
      if (logs.every((y) => !y.success)) {
        state = SystemStateEnum.error;
      }
    }

    return state;
  };

  return (
    <div className="animation">
      <div
        style={{ marginLeft: "2rem" }}
        className={`system-container ${
          systemState.replica === SystemStateEnum.error
            ? "error"
            : systemState.replica === SystemStateEnum.warning
              ? "warning"
              : ""
        }`}
      >
        <p>LMS Replica Database Import Functie</p>
      </div>

      {systemState.incidentFacade !== SystemStateEnum.error &&
      systemState.replica !== SystemStateEnum.error ? (
          <div
            className={`container ${
              systemState.incidentFacade === SystemStateEnum.warning ||
            systemState.replica === SystemStateEnum.warning
                ? "warning"
                : ""
            }`}
          >
            <div className="path"></div>
            <div className="path"></div>
            <div className="path"></div>
            <div className="path"></div>
            <div className="path"></div>
            <div className="path"></div>
            <div className="path"></div>
            <div className="path"></div>
          </div>
        ) : (
          <div className="container">
            <div className="fault">X</div>
            <div className="fault">X</div>
            <div className="fault">X</div>
            <div className="fault">X</div>
            <div className="fault">X</div>
            <div className="fault">X</div>
            <div className="fault">X</div>
            <div className="fault">X</div>
          </div>
        )}

      <div
        style={{ marginRight: "2rem", float: "right" }}
        className={`system-container ${
          systemState.incidentFacade === SystemStateEnum.error
            ? "error"
            : systemState.incidentFacade === SystemStateEnum.warning
              ? "warning"
              : ""
        }`}
      >
        <p>VRMWB Incident Voorziening</p>
      </div>
    </div>
  );
};

export default IncidentFlow;
