import { faSquare, faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style.module.scss";

type Props = {
  label?: string;
  internalValue?: boolean;
  onValueChange?: (val: boolean) => void;
  disabled?: boolean;
  required?: boolean;
};

export default function Checkbox({
  label,
  internalValue,
  onValueChange,
  disabled,
  required
}: Readonly<Props>) {

  function onClick() {
    if (onValueChange)
      onValueChange(!internalValue);
  }

  return (
    <div className={`${style.checkboxWrapper} ${disabled ? style.disabled : ""}`} onClick={onClick}>
      {label && <label>{label}</label>}
      {internalValue && <FontAwesomeIcon className={style.checkedIcon} icon={faSquareCheck} />}
      {!internalValue && <FontAwesomeIcon className={style.uncheckedIcon} icon={faSquare} />}
      <input
        hidden={true}
        readOnly={disabled ?? false}
        required={required ?? false}
        checked={internalValue}
        onChange={(event) => {
          if (onValueChange)
            onValueChange(event.currentTarget.checked);
        }}
        type="checkbox" />
    </div>
  );
}