import style from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

/**
 * An edit button with a pen icon
 * @param onClick - The function to call when the button is clicked
 */
export function EditButton({onClick}: Readonly<{onClick: () => void}>) {
  return(
    <button className={style.editButton} onClick={onClick}>
      <FontAwesomeIcon icon={faPen} />
    </button>
  );
}
