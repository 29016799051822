import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

/**
 * A remove button with a trash icon
 * @param onClick - The function to call when the button is clicked
 */
export function StopButton({ onClick }: Readonly<{ onClick: () => void }>) {
  return (
    <button className={style.removeButton} onClick={onClick}>
      <FontAwesomeIcon icon={faBan} />
    </button>
  );
}
