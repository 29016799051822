import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

/**
 * An add button with a plus icon
 * @param onClick - The function to call when the button is clicked
 */
export function AddButton({ title, onClick }: Readonly<{ title?: string, onClick: () => void }>) {
  return (
    <button className={`${style.addButton} ${title ? style.hasTitle : ""}`} onClick={onClick}>
      {title && <div className={style.label}>{title}</div>}
      <FontAwesomeIcon icon={faPlus} />
    </button>
  );
}
