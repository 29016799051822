import Select from "react-select";
import { DetermineApiUri } from "../../../config";
import { useProtectedFetch } from "../../hooks/useProtectedFetch";
import useSWR from "swr";
import { useEffect, useState } from "react";
import { Oefenincident } from "../../models/Oefenincident";

type MeldingClasificatieProps = {
  oefenIncident: Oefenincident;
  handleOefenIncident: (oefenIncident: Oefenincident) => void;
};

interface MeldingClasificatieData {
  classificatie: string;
  subClassificaties: MeldingClasificatieData[];
}

function MeldingClasificatie({
  oefenIncident,
  handleOefenIncident,
}: Readonly<MeldingClasificatieProps>) {
  const [selectedClassificatie, setSelectedClassificatie] = useState<string | undefined>(oefenIncident.brwMeldingCl);
  const [selectedClassificatie1, setSelectedClassificatie1] = useState<string | undefined>(oefenIncident.brwMeldingCl1);
  const [selectedClassificatie2, setSelectedClassificatie2] = useState<string | undefined>(oefenIncident.brwMeldingCl2);

  const [classificaties, setClassificaties] = useState<MeldingClasificatieData[] | undefined>([]);
  const [classificaties1, setClassificaties1] = useState<MeldingClasificatieData[] | undefined>([]);
  const [classificaties2, setClassificaties2] = useState<MeldingClasificatieData[] | undefined>([]);

  const { data } = useSWR<MeldingClasificatieData[]>(`${DetermineApiUri()}/api/Meldingclassificaties`,
    {
      fetcher: useProtectedFetch(),
    }
  );

  useEffect(() => {
    if (data) setClassificaties(data);
  }, [data]);

  useEffect(() => {
    setSelectedClassificatie1(undefined);
    setSelectedClassificatie2(undefined);

    const classificatieOptions = classificaties?.find(
      (x) => x.classificatie === selectedClassificatie
    );
    setClassificaties1(classificatieOptions?.subClassificaties ?? []);

    if (selectedClassificatie) {
      handleOefenIncident({
        ...oefenIncident,
        brwMeldingCl: selectedClassificatie,
      });
    }
  }, [selectedClassificatie]);

  useEffect(() => {
    setSelectedClassificatie2(undefined);

    const classificatieOptions = classificaties1?.find(
      (x) => x.classificatie === selectedClassificatie1
    );
    setClassificaties2(classificatieOptions?.subClassificaties ?? []);

    if (selectedClassificatie1) {
      handleOefenIncident({
        ...oefenIncident,
        brwMeldingCl1: selectedClassificatie1,
      });
    }
  }, [selectedClassificatie1]);

  useEffect(() => {
    if (selectedClassificatie2) {
      handleOefenIncident({
        ...oefenIncident,
        brwMeldingCl2: selectedClassificatie2,
      });
    }
  }, [selectedClassificatie2]);

  return (
    <>
      <div className={"dropdown-wrapper"}>
        <div className={"label"}>Classificatie 1</div>
        <Select
          name="classificatie1"
          className="search-select-container"
          classNamePrefix="search-select"
          placeholder="Classificatie 1"
          required={true}
          options={
            classificaties?.map((x) => ({
              value: x.classificatie,
              label: x.classificatie,
            })) ?? []
          }
          isSearchable={true}
          value={
            selectedClassificatie
              ? { value: selectedClassificatie, label: selectedClassificatie }
              : null
          }
          onChange={(selectedOption) =>
            setSelectedClassificatie(selectedOption?.value)
          }
        />
      </div>
      <div className={"dropdown-wrapper"}>
        <div className={"label"}>Classificatie 2</div>
        <Select
          name="classificatie2"
          className="search-select-container"
          classNamePrefix="search-select"
          placeholder="Classificatie 2"
          options={
            classificaties1?.map((x) => ({
              value: x.classificatie,
              label: x.classificatie,
            })) ?? []
          }
          isSearchable={true}
          value={
            selectedClassificatie1
              ? { value: selectedClassificatie1, label: selectedClassificatie1 }
              : null
          }
          onChange={(selectedOption) =>
            setSelectedClassificatie1(selectedOption?.value)
          }
        />
      </div>
      <div className={"dropdown-wrapper"}>
        <div className={"label"}>Classificatie 3</div>
        <Select
          name="classificatie3"
          className="search-select-container"
          classNamePrefix="search-select"
          placeholder="Classificatie 3"
          options={
            classificaties2?.map((x) => ({
              value: x.classificatie,
              label: x.classificatie,
            })) ?? []
          }
          isSearchable={true}
          value={
            selectedClassificatie2
              ? { value: selectedClassificatie2, label: selectedClassificatie2 }
              : null
          }
          onChange={(selectedOption) =>
            setSelectedClassificatie2(selectedOption?.value)
          }
        />
      </div>
    </>
  );
}

export default MeldingClasificatie;
