import {ReactNode} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {classNames} from "../utils/classNames";
import "./style.scss";

interface MainLayoutProps {
  children?: ReactNode;
}

export default function MainLayout({children}: Readonly<MainLayoutProps>) {
  const location = useLocation();

  return (
    <main>
      <div className="main">
        <div
          className={classNames(
            "page-content", `page-${location.pathname.substring(1)}`)}>
          {children ?? <Outlet/>}
        </div>
      </div>
    </main>
  );
}
