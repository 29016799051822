import { createRef, useState } from "react";
import TextInput from "../components/UI/InputElements/TextInput";
import { Datepicker } from "../components/UI/InputElements/DatePicker";
import TextArea from "../components/UI/InputElements/TextArea";
import { LoadingComponent } from "../components/LoadingComponent";
import { Karakteristiek, Oefenincident, KladblokRegel, InzetGespreksgroep, InzetEenheid } from "../models/Oefenincident";
import { SelectOption } from "../models/SelectOption";
import Select, { MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useEenheden } from "../hooks/useEenheden";
import Checkbox from "../components/UI/InputElements/Checkbox";
import MeldingClasificatie from "./OefenincidentComponents/OefenincidentMeldingsClasificatie";

// Generate a random number for the incident number of 7 digits
function generateIncidentNr(): number {
  const min = 100000;
  const max = 999999;

  // Generate a random number within the range
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNumber;
}

export function OefenincidentForm({ initOefenincident, onSubmit }: Readonly<{ onSubmit: (oefenIncident: Oefenincident) => void, initOefenincident?: Oefenincident }>) {
  const form = createRef<HTMLFormElement>();
  const { eenheden, selectOptions } = useEenheden();
  const [inzetEenheden] = useState<InzetEenheid[]>(initOefenincident?.eenheden ?? []);
  const [oefenIncident, setOefenIncident] = useState<Oefenincident>(initOefenincident ?? {
    incidentNr: `999${generateIncidentNr()}`,
    eenheden: [],
    newEenheden: [],
    dtgStartIncident: new Date(),
    indDiscIncidentAmbulance: false,
    indDiscIncidentPolitie: false,
  });

  const [opschaling, setOpschaling] = useState<Karakteristiek>(oefenIncident?.opschaling ?? { naamKarakteristiek: "Ops br" });
  const [initiëleKladblokregel, setInitiëleKladblokregel] = useState<KladblokRegel>(oefenIncident?.initiëleKladblokregel ?? { userName: "Meldkamer" });
  const [inzetGespreksgroep, setInzetGespreksgroep] = useState<InzetGespreksgroep>(oefenIncident?.inzetGespreksgroep ?? { gespreksgroepNaam: "" });

  const priorityOptions = [
    { value: "1", label: "Prio 1" },
    { value: "2", label: "Prio 2" },
    { value: "3", label: "Prio 3" },
    { value: "4", label: "Prio 4" },
    { value: "5", label: "Prio 5" },
  ];

  if (!eenheden || !selectOptions)
    return <LoadingComponent />;

  const handleNewEenheidInputChange = (value: string, index: number, property: string) => {
    setOefenIncident(prevState => ({
      ...prevState,
      newEenheden: (prevState.newEenheden ?? []).map((eenheden, i) =>
        i === index ? { ...eenheden, [property]: value } : eenheden
      )
    }));
  };

  function OnEenhedenChange(values: MultiValue<SelectOption>) {
    if (!selectOptions)
      return;

    const roepnaamEenheden = values.map(x => x.value);
    const existingInzetEenheden = inzetEenheden.filter(x => roepnaamEenheden.includes(x.roepnaamEenheid ?? ""));
    const chosenEenheid: InzetEenheid[] = eenheden?.filter(x => roepnaamEenheden.includes(x.roepnaamEenheid) && existingInzetEenheden.every(e => e.roepnaamEenheid !== x.roepnaamEenheid))?.map(x => {
      return {
        naamVoertuig: x.roepnaamEenheid,
        kazNaam: x.kazNaam,
        tIndDiscEenheid: "B",
        roepnaamEenheid: x.roepnaamEenheid,
        dtgOpdrachtInzet: new Date(),
        codeVoertuigsoort: x.codeVoertuigSoort,
        incidentId: oefenIncident.incidentId
      };
    }) ?? [];

    const newItems = roepnaamEenheden.filter(x => !selectOptions.some(e => e.value === x)).map(item => ({
      roepnaamEenheid: item,
      codeVoertuigSoort: "",
      omsVoertuigSoort: "",
      kazNaam: "",
      kazAfk: "",
      tIndDiscEenheid: "B",
    }));

    setOefenIncident({ ...oefenIncident, eenheden: existingInzetEenheden.concat(chosenEenheid), newEenheden: newItems });
  }

  return (
    <form ref={form}>
      <div className={"horizontal-form-row"}>
        <TextInput label={"Incidentnummer"} internalValue={oefenIncident.incidentNr} disabled={true} />
        <div className={"dropdown-wrapper"}>
          <div className={"label"}>Prioriteit</div>
          <Select
            className={"search-select-container"}
            classNamePrefix="search-select"
            placeholder={"Kies de prioriteit..."} options={priorityOptions}
            isSearchable={false}
            defaultValue={priorityOptions.find(x => x.value === oefenIncident.prioriteitIncidentBrandweer)}
            onChange={(value: unknown) => {
              setOefenIncident({ ...oefenIncident, prioriteitIncidentBrandweer: (value as SelectOption).value });
            }} />
        </div>
      </div>
      <div className={"horizontal-form-row"}>
        <TextInput label={"Gesprekgroep"} internalValue={inzetGespreksgroep.gespreksgroepNaam} onValueChange={(value: string) => setInzetGespreksgroep({ ...inzetGespreksgroep, gespreksgroepNaam: value })} />
        <TextInput label={"Opschaling"} internalValue={opschaling.actueleKarWaarde} onValueChange={(value: string) => setOpschaling({ ...opschaling, actueleKarWaarde: value })} />
      </div>
      <div className={"horizontal-form-row"}>
        <Datepicker required={true} onValueChange={(value?: Date) => setOefenIncident({ ...oefenIncident, dtgStartIncident: value })} value={new Date(oefenIncident.dtgStartIncident ?? "")} label={"Startdatum *"} />
        <Datepicker onValueChange={(value?: Date) => setOefenIncident({ ...oefenIncident, dtgEindeIncident: value })} value={oefenIncident.dtgEindeIncident ? new Date(oefenIncident.dtgEindeIncident) : undefined} label={"Einddatum"} />
      </div>
      <hr />
      <div className={"horizontal-form-row"}>
        <TextInput required={true} label={"Plaatsnaam *"} internalValue={oefenIncident.plaatsNaam} onValueChange={(value: string) => setOefenIncident({ ...oefenIncident, plaatsNaam: value })} />
      </div>
      <div className={"horizontal-form-row"}>
        <TextInput required={true} label={"Straatnaam *"} internalValue={oefenIncident.straatNaam} onValueChange={(value: string) => setOefenIncident({ ...oefenIncident, straatNaam: value })} />
        <TextInput label={"Huisnummer"} internalValue={oefenIncident.huisnummer?.toString()} onValueChange={(value: string) => setOefenIncident({ ...oefenIncident, huisnummer: parseInt(value) })} />
      </div>
      <div className={"vertical-form-row"}>
        <p className={"label"}>Disciplines</p>
        <div className={"multi-checkbox-wrapper"}>
          <Checkbox label="Politie" internalValue={oefenIncident.indDiscIncidentPolitie} onValueChange={(value: boolean) => setOefenIncident({ ...oefenIncident, indDiscIncidentPolitie: value })} />
          <Checkbox label="Brandweer" internalValue={true} disabled={true} />
          <Checkbox label="Ambulance" internalValue={oefenIncident.indDiscIncidentAmbulance} onValueChange={(value: boolean) => setOefenIncident({ ...oefenIncident, indDiscIncidentAmbulance: value })} />
        </div>
      </div>
      <hr />
      <div className={"horizontal-form-row"}>
        <MeldingClasificatie oefenIncident={oefenIncident} handleOefenIncident={setOefenIncident} />
      </div>
      <p className={"example"}>{"Bijvoorbeeld: \"Brand - Gebouw - Woning/Woongebouw\" of \"Dienstverlening - Brandweer - Liftopsluiting\""}</p>
      <hr />
      <div className={"label"}>Roepnummers</div>
      <div className={"horizontal-form-row"}>
        <div className={"dropdown-wrapper"}>
          <CreatableSelect className={"search-select-container"}
            classNamePrefix="search-select"
            placeholder={"Type hier een roepnummer..."}
            options={selectOptions}
            onChange={OnEenhedenChange}
            defaultValue={selectOptions.filter(x => oefenIncident.eenheden?.map(x => x.roepnaamEenheid).includes(x.value))}
            isMulti={true}
            hideSelectedOptions={true}
            isSearchable={true}
            isValidNewOption={(inputValue: string) => {
              const existsInEenhedenOptions = selectOptions.some(e => e.value === inputValue);
              return !existsInEenhedenOptions && inputValue.length === 6;
            }}
            formatCreateLabel={(inputValue: string) => {
              const existsInEenhedenOptions = selectOptions.some(e => e.value === inputValue);
              return existsInEenhedenOptions ? inputValue : `Nieuwe eenheid aanmaken "${inputValue}"`;
            }}
            getOptionLabel={(option: SelectOption) => {
              return selectOptions.some(e => e.value === option.value) ? option.label : `${option.label}*`;
            }} />

        </div>
      </div>
      {(oefenIncident?.newEenheden?.length ?? 0) > 0 && (
        <div>
          <h3>{oefenIncident?.newEenheden?.length} Onbekende eenheden:</h3>
          {oefenIncident?.newEenheden?.map((item, index) => (
            <div className={"horizontal-form-row"} key={index}>
              <TextInput label={"RoepNaam"} internalValue={item.roepnaamEenheid} disabled={true} />
              <TextInput label={"Discipline"} internalValue={item.tIndDiscEenheid} onValueChange={(value: string) => handleNewEenheidInputChange(value, index, "tIndDiscEenheid")} />
              <TextInput label={"Voertuigtype"} onValueChange={(value: string) => handleNewEenheidInputChange(value, index, "codeVoertuigSoort")} />
              <TextInput label={"Voertuigomschrijving"} onValueChange={(value: string) => handleNewEenheidInputChange(value, index, "omsVoertuigSoort")} />
              <TextInput label={"Kazerne"} onValueChange={(value: string) => handleNewEenheidInputChange(value, index, "kazNaam")} />
              <TextInput label={"Kazerne Afk"} onValueChange={(value: string) => handleNewEenheidInputChange(value, index, "kazAfk")} />
            </div>
          ))}
        </div>
      )}
      <hr />
      <div className={"horizontal-form-row"}>
        <TextArea label={"Initiële kladblokregel"} internalValue={initiëleKladblokregel.inhoudKladblokRegel} onValueChange={(value: string) => setInitiëleKladblokregel({ ...initiëleKladblokregel, inhoudKladblokRegel: value })} />
      </div>
      <p className={"example"}>De kladblok regel wordt toegevoegd aan het incident onder de naam van Meldkamer</p>
      <div className={"horizontal-form-row submit-row"}>
        <button className={"submit"} onClick={(event) => {
          event.preventDefault();
          if (!form.current?.checkValidity()) {
            form.current?.reportValidity();
          } else {
            oefenIncident.opschaling = opschaling;
            oefenIncident.initiëleKladblokregel = initiëleKladblokregel;
            inzetGespreksgroep.dateCreated = new Date();
            oefenIncident.inzetGespreksgroep = inzetGespreksgroep;
            onSubmit(oefenIncident);
          }
        }}>Opslaan</button>
      </div>
    </form>
  );
}
