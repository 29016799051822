import { DetermineApiUri } from "../../config";
import { Incident } from "../models/Incident";
import useSWR from "swr";
import { useProtectedFetch } from "./useProtectedFetch";

/**
 * Hook for fetching incidents from the OIV backend
 * @param skip Skip the first n incidents
 * @param take Take the first n incidents
 */
export function useIncidents(skip: number, take: number) {
  const { data, error } = useSWR<Incident[]>(
    `${DetermineApiUri()}/api/incident?status=All&take=${take}&skip=${skip}`,
    useProtectedFetch(),
    { refreshInterval: 5000 }
  );

  return {
    incidents: data,
    isLoading: !error && !data,
    isError: error,
  };
}
