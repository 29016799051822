import style from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

/**
 * A submit button with a check icon
 * @param onClick - The function to call when the button is clicked
 */
export function SubmitButton({onClick}: Readonly<{onClick: () => void}>) {
  return(
    <button className={style.submitButton} onClick={onClick}>
      <FontAwesomeIcon icon={faCheck} />
    </button>
  );
}
