import { useEffect, useState } from "react";
import useSWR from "swr";
import { TableState } from "../../models/TableState";
import { useProtectedFetch } from "../../hooks/useProtectedFetch";
import { Incident } from "../../models/Incident";
import { DetermineApiUri } from "../../../config";
import { LoadingComponent } from "../../components/LoadingComponent";
import { IncidentRowView } from "../../components/IncidentRowView";
import { LoadMoreButton } from "../../components/TextButtons/LoadMoreButton";
import style from "./style.module.scss";
import { createConfirmation } from "react-confirm";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";
import ActivateConfirmDialog from "./components/ActivateConfirmDialog";

/**
 *  IncidentManagement page
 * @returns IncidentManagement page
 */
export default function IncidentManagement() {
  const fetcher = useProtectedFetch("none");
  const confirmDeactivate = createConfirmation(DeactivateConfirmDialog);
  const confirmActivate = createConfirmation(ActivateConfirmDialog);

  const [activeIncidentsTableState, setActiveIncidentsTableState] = useState<TableState>({ currentPage: 1, pageSize: 7 });
  const [disabledIncidentsTableState, setDisabledIncidentsTableState] = useState<TableState>({ currentPage: 1, pageSize: 10 });
  const [hasMoreActiveIncidents, setHasMoreActiveIncidents] = useState(true);
  const [hasMoreDisabledIncidents, setHasMoreDisabledIncidents] = useState(true);

  const { data: activeIncidents, isLoading: activeIncidentsIsLoading, mutate: mutateActiveIncidents } = useSWR<Incident[]>(`${DetermineApiUri()}/api/incident?status=active&skip=0&take=100`, useProtectedFetch());
  const { data: disabledIncidents, isLoading: disabledIncidentsIsLoading, mutate: mutateDisabledIncidents } = useSWR<Incident[]>(`${DetermineApiUri()}/api/incident?status=disabled&skip=0&take=${disabledIncidentsTableState.currentPage * disabledIncidentsTableState.pageSize}`, useProtectedFetch(),
    {
      onSuccess(data) {
        if (data.length < disabledIncidentsTableState.currentPage * disabledIncidentsTableState.pageSize) {
          setHasMoreDisabledIncidents(false);
        }
      },
    });

  /**
   * Function to revalidate cache
   */
  async function RevalidateCache() {
    mutateActiveIncidents();
    mutateDisabledIncidents();
  }

  /**
   * Check if there are more active incidents
   */
  useEffect(() => {
    if (activeIncidents && activeIncidents.length < activeIncidentsTableState.currentPage * activeIncidentsTableState.pageSize) {
      setHasMoreActiveIncidents(false);
    }
  }, [activeIncidentsTableState]);

  /**
   *  Function to disable an incident
   * @param incidentId Incident that will be disabled
   */
  async function onDisable(incidentId: number) {
    if (!await confirmDeactivate({}))
      return;

    await fetcher(
      `${DetermineApiUri()}/api/incident/disable/${incidentId}`,
      {
        method: "POST",
      }
    );

    await RevalidateCache();
  }

  /**
   *  Function to disable an incident
   * @param incidentId Incident that will be disabled
   */
  async function onReactivate(incidentId: number) {
    if (!await confirmActivate({}))
      return;

    await fetcher(
      `${DetermineApiUri()}/api/incident/reactivate/${incidentId}`,
      {
        method: "POST",
      }
    );

    await RevalidateCache();
  }

  return (
    <>
      <div className="content-container">
        <h1>Zichtbare incidenten</h1>
        <p>Hieronder staat een overzicht van incidenten die zijn gedeactiveerd. Dit betekend dat zij niet meer zichtbaar zijn in de OIV-Viewer. Het is mogelijk om deze weer te activeren zodat ze weer zichtbaar zijn.</p>
        {(!activeIncidents || activeIncidentsIsLoading) ? <LoadingComponent /> : activeIncidents.slice(0, activeIncidentsTableState.currentPage * activeIncidentsTableState.pageSize).map((incident) => (
          <IncidentRowView key={incident.incidentId} isDisabled={false} incident={incident} onDisable={onDisable} />
        ))}
        {hasMoreActiveIncidents && <LoadMoreButton className={style.loadMore} onClick={function (): void {
          setActiveIncidentsTableState({ ...activeIncidentsTableState, currentPage: activeIncidentsTableState.currentPage + 1 });
        }} />}
      </div>
      <div className="content-container">
        <h1>Gedeactiveerde incidenten</h1>
        <p>Hieronder staat een overzicht van incidenten die zijn gedeactiveerd. Dit betekend dat zij niet meer zichtbaar zijn in de OIV-Viewer. Het is mogelijk om deze weer te activeren zodat ze weer zichtbaar zijn.</p>
        {(!disabledIncidents || disabledIncidentsIsLoading) && <LoadingComponent />}
        {disabledIncidents?.map((incident) => (
          <IncidentRowView key={incident.incidentId} isDisabled={true} incident={incident} onReactivate={onReactivate} />
        ))}
        {hasMoreDisabledIncidents && <LoadMoreButton className={style.loadMore} onClick={function (): void {
          setDisabledIncidentsTableState({ ...disabledIncidentsTableState, currentPage: disabledIncidentsTableState.currentPage + 1 });
        }} />}
      </div>
    </>
  );
}
