import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";

/**
 * A button that represents the meldkamer
 * @param onClick - The function to call when the button is clicked
 */
export function MeldkamerButton({ onClick }: Readonly<{ onClick: () => void }>) {
  return (
    <button className={style.meldkamerButton} onClick={onClick}>
      <FontAwesomeIcon icon={faHeadset} />
    </button>
  );
}