import { OivNotification } from "../../../models/Notification";
import { Notification } from "../notification";
import "./style.scss";

interface NotificationPreviewProps {
  notifications: OivNotification[];
}

export const NotificationPreview = ({
  notifications,
}: NotificationPreviewProps) => {
  return (
    <>
      <div className="notification-preview">
        {notifications.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </div>
    </>
  );
};
