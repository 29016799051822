import "./style.scss";
import { OefenincidentForm } from "../../../forms/OefenincidentForm";
import { useProtectedFetch } from "../../../hooks/useProtectedFetch";
import { DetermineApiUri } from "../../../../config";
import { defaultToastOptions } from "../../../utils/defaultToastOptions";
import { ToastContainer, toast } from "react-toastify";
import { Oefenincident } from "../../../models/Oefenincident";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes";

export function OefenincidentenAddPage() {
  const request = useProtectedFetch("none");
  const navigate = useNavigate();

  async function postOefenincident(oefenIncident: Oefenincident) {
    try {
      await request(`${DetermineApiUri()}/api/oefenincident`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(oefenIncident),
      });
      toast.success("Oefenincident aangemaakt.", defaultToastOptions);
      navigate(Routes.OefenincidentOverview);
    } catch (error) {
      toast.error(
        "Er is iets fout gegaan met het toevoegen",
        defaultToastOptions
      );
    }
  }

  return (
    <div className={"content-container"}>
      <ToastContainer />
      <h1>Nieuw oefenincidenten</h1>
      <p> Maak hier een oefenincident aan die zichtbaar is in de OIV-Viewer</p>
      <OefenincidentForm onSubmit={postOefenincident} />
    </div>
  );
}