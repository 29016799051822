import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useRef } from "react";
import { mutate } from "swr";
import { DetermineApiUri } from "../../../../config";
import { useProtectedFetch } from "../../../hooks/useProtectedFetch";
import { OivNotification } from "../../../models/Notification";
import "./style.scss";
import { ToolBar } from "./ToolBar";

interface NotificationEditorProps {
  notification?: OivNotification;
}

export const NotificationEditor = ({
  notification,
}: NotificationEditorProps) => {
  const request = useProtectedFetch();
  const dateInputRef = useRef<HTMLInputElement>(null);

  function handleDateChange(event: any) {
    console.log("Selected date:", event.target.value);
  }

  const handleIconClick = () => {
    if (dateInputRef.current) dateInputRef.current.showPicker();
  };

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: notification?.notificationNode || "",
  });

  if (!editor) {
    return null;
  }

  const CreateNotification = async () => {
    const date = new Date(dateInputRef.current?.value || new Date());
    const json = editor.getJSON();

    const notification: OivNotification = {
      date: date,
      notificationNode: json,
    };

    const response = await request(`${DetermineApiUri()}/api/notification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notification),
    });

    if (response.status !== 200) {
      console.log("Error creating notification");
      console.log(response);
      return;
    }

    mutate(`${DetermineApiUri()}/api/notification`);
  };

  const UpdateNotification = async () => {
    if (!notification) return;

    const date = new Date(dateInputRef.current?.value || new Date());
    const json = editor.getJSON();

    const updatedNotification: OivNotification = {
      id: notification?.id,
      date: date,
      notificationNode: json,
    };

    const response = await request(`${DetermineApiUri()}/api/notification`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedNotification),
    });

    if (response.status !== 200) {
      console.log("Error updating notification");
      console.log(response);
      return;
    }

    mutate(`${DetermineApiUri()}/api/notification`);
  };

  const hasContent = () => {
    const editorContent = editor.getHTML();
    const textContent = editorContent
      .replace(/(<([^>]+)>)/gi, "") // Remove HTML tags
      .trim(); // Remove whitespace

    return textContent.length > 0;
  };

  if (notification) {
    console.log(notification.date);
  }

  return (
    <div id="notification-editor">
      <ToolBar editor={editor} />
      <EditorContent editor={editor} />
      <div>
        <div className="submit-row">
          <div className="datepicker-container">
            <span>Datum melding :</span>

            <input
              type="date"
              id="date"
              name="date"
              className="datepicker"
              onChange={handleDateChange}
              ref={dateInputRef}
              value={notification?.date?.toString().split("T")[0]}
            />
            <button className="svg-button" onClick={handleIconClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
            </button>
          </div>
          {/* Button is disabled if no content */}
          <button
            disabled={!hasContent()}
            onClick={notification ? UpdateNotification : CreateNotification}
          >
            {notification ? "Bewerken" : "Melding aanmaken"}
          </button>
        </div>
      </div>
    </div>
  );
};
