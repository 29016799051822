import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

/***
 * Option that will be used within the dropdown
 */
export interface DropdownOption {
  label: string;
  value: string;
}

type Props = {
  options: Array<DropdownOption>;
  value: string | null | undefined;
  label: string | null | undefined;
  onValueChanged: (id: string) => void;
};

/**
 * Dropdown component that supports categorized values
 * @param options
 * @param value
 * @param onValueChanged
 * @constructor
 */
export function DropDown({ options, label, value, onValueChanged }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="dropdown-wrapper">
      {label && <label>{label}</label>}
      <div className={isOpen ? "dropdown open" : "dropdown"}>
        <div
          className="dropdown-label"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span className="title">{options.find(x => x.value == value)?.label}</span>
          {isOpen ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </div>
        <div className="options">
          {options.map((o) => (
            <div key={`dropdown_${o.value}`} className="categorized-values">
              <div
                className="category-label"
                onClick={() => {
                  onValueChanged(o.value);
                  setIsOpen(false);
                }}
              >
                <label>{o.label}</label>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
