import style from "./style.module.scss";

type Props = {
  politie: boolean;
  brandweer: boolean;
  ambulance: boolean;
}

/**
 * Component displaying the disciplines of an incident
 * @param disciplines - the disciplines of the incident, e.g. ["-AB", "P--"]
 * @constructor
 */
export function DisciplineIndicator({ politie, brandweer, ambulance }: Readonly<Props>) {
  return (
    <div className={style.disciplineIndicator}>
      {politie &&
        <div style={{ backgroundColor: "blue", flex: 1 }} />}
      {brandweer &&
        <div style={{ backgroundColor: "red", flex: 1 }} />}
      {ambulance &&
        <div style={{ backgroundColor: "yellow", flex: 1 }} />}
    </div>
  );
}