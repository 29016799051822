import {useNotifications} from "../../hooks/useNotifications";
import {NotificationPreview} from "./notification-preview";
import {NotificationEditor} from "./notificationEditor";
import "./style.scss";
import {LoadingComponent} from "../LoadingComponent";

export const NotificationManagement = () => {
  const {notifications} = useNotifications();

  if (!notifications) return (
    <div className={"content-container"}>
      <LoadingComponent/>
    </div>
  );

  return (
    <div className={"content-container"}>
      <NotificationEditor/>
      <NotificationPreview notifications={notifications}/>
    </div>
  );
};
