import { confirmable } from "react-confirm";
import style from "./style.module.scss";
import { ConfirmButton } from "../../../components/TextButtons/ConfirmButton";
import { CancelButton } from "../../../components/TextButtons/CancelButton";

function DeactivateConfirmDialog({ show, proceed, confirmation, options }: any) {
  return (
    <div className={style.overlay}>
      <div className={style.dialog}>
        <h1>Weet je zeker dat je het incident wil deactiveren?</h1>
        <p>Dit incident is, na deactivatie, niet meer te zien in de OIV-Viewer.</p>
        <div className={style.actions}>
          <ConfirmButton text="Deactiveer" onClick={() => proceed(true)} />
          <CancelButton text="Annuleer" onClick={() => proceed(false)} />
        </div>
      </div>
    </div>
  );
}

export default confirmable(DeactivateConfirmDialog);