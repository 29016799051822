import { EenhedenGroep } from "../../models/EenhedenGroep";
import style from "./style.module.scss";
import { EenheidGroepForm } from "../../forms/EenheidgroepForm/EenheidGroepForm";
import { AddButton } from "../../components/IconButtons/AddButton";
import useSWR from "swr";
import { DetermineApiUri } from "../../../config";
import { useProtectedFetch } from "../../hooks/useProtectedFetch";
import { LoadingComponent } from "../../components/LoadingComponent";
import { useEffect, useState } from "react";
import { useEenheden } from "../../hooks/useEenheden";

/**
 * Page for grouping units
 * @constructor
 */
export function EenhedenPage() {
  const { selectOptions } = useEenheden();
  const [groups, setGroups] = useState<EenhedenGroep[]>([]);
  const [usedEenheden, setUsedEenheden] = useState<string[]>([]);
  const { data, mutate } = useSWR<EenhedenGroep[]>(
    `${DetermineApiUri()}/api/eenhedengroup`,
    useProtectedFetch(),
  );

  /**
   * Add a new group
   */
  function onAdd() {
    setGroups([...groups, { id: "", name: "", description: "", vehicleCodes: [], isNew: true }]);
  }

  /**
   * Update the groups when the data is loaded
   */
  useEffect(() => {
    if (data) {
      setGroups(data);
    }
  }, [data]);

  /**
   * Update the used eenheden when the groups are loaded
   */
  useEffect(() => {
    if (groups)
      setUsedEenheden(groups.flatMap(x => x.vehicleCodes));
  }, [groups]);

  /**
   * Return a loading component when the data is not loaded
   */
  if (!data || !selectOptions)
    return (<LoadingComponent />);

  /**
   * Update the groups when a change is made
   */
  function onChange() {
    mutate([]);
  }

  return (
    <div className={"content-container"} style={{ width: "calc(100vw - 110px)" }}>
      <h1>Eenheden groeperen</h1>
      <p> Hier is het mogelijk om eenheden aan elkaar te verbinden d.m.v. groepen. <br /> <b>Let op dat de eenheden hierdoor
        elkaars incidenten kunnen inzien.</b></p>
      <hr />
      <br />
      <div className={style.formWrapper}>
        <div className={style.header}>
          <div>Groepsnaam</div>
          <div>Beschrijving</div>
          <div>Eenheden</div>
          <div></div>
        </div>
        {groups?.map(e =>
          <EenheidGroepForm
            key={e.id}
            onChange={onChange}
            eenhedenGroep={e}
            eenhedenOptions={selectOptions.filter(x => !usedEenheden.filter(y => !e.vehicleCodes.includes(y)).includes(x.value))} />
        )}
        <div className={style.add}>
          <AddButton onClick={() => onAdd()} />
        </div>
      </div>
    </div>
  );
}
