import { useState } from "react";
import "./style.scss";
import { TestIncident } from "../../models/TestIncident";
import TextInput from "../UI/InputElements/TextInput";
import { DropDown, DropdownOption } from "../UI/InputElements/DropDown";
import { Datepicker } from "../UI/InputElements/DatePicker";
import NumberInput from "../UI/InputElements/NumberInput";
import VariationButton from "../UI/Buttons/VariationButton";
import { TestEenheid } from "../../models/TestEenheid";
import { useProtectedFetch } from "../../hooks/useProtectedFetch";
import { DetermineApiUri } from "../../../config";
import { toast } from "react-toastify";
import { defaultToastOptions } from "../../utils/defaultToastOptions";

export default function AddIncident() {
  const priorityOptions: DropdownOption[] = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];
  const vehicleNameMaxLength = 8;
  const defaultTestIncident: TestIncident = {
    incidentNr: 0,
    dtgStartIncident: new Date(),
    dtgEindeIncident: undefined,
    BrwDtgStart: new Date(),
    BrwDtgEinde: undefined,
    brwMeldingCl: "",
    brwMeldingCl1: "",
    brwMeldingCl2: "",
    city: "",
    street: "",
    postalCode: "",
    houseNumber: 0,
    houseNumberAddition: "",
    priority: priorityOptions[0].value,
    testEenheden: [{ kazNaam: "", naamVoertuig: "" }],
  };
  const [testIncident, setTestIncident] =
    useState<TestIncident>(defaultTestIncident);
  const protectedFetch = useProtectedFetch();
  const [addBtnDisabled, setAddBtnDisabled] = useState(false);

  async function onAddIncident() {
    try {
      setAddBtnDisabled(true);
      await protectedFetch(`${DetermineApiUri()}/api/incident`, {
        method: "post",
        headers: {
          "content-type": "application/json: charset=utf-8",
        },
        body: JSON.stringify(testIncident),
      });

      toast.success("Incident toegevoegd.", defaultToastOptions);
    } catch (error) {
      toast.error(
        "Er is iets fout gegaan met het toevoegen",
        defaultToastOptions
      );
    }
    setAddBtnDisabled(false);
    setTestIncident(defaultTestIncident);
  }

  function addEenheidRow() {
    testIncident.testEenheden = [
      ...testIncident.testEenheden,
      { kazNaam: "", naamVoertuig: "" },
    ];
    setTestIncident({ ...testIncident });
  }

  function removeEenheidRow(testEenheid: TestEenheid) {
    if (testIncident.testEenheden.length <= 1) {
      return;
    }

    const newTestEenheden = testIncident.testEenheden.filter(
      (x) => x !== testEenheid
    );
    const newTestIncident = { ...testIncident };
    newTestIncident.testEenheden = newTestEenheden;
    setTestIncident(newTestIncident);
  }

  return (
    <div className="add-incident-component-container">
      <div className="form-container">
        <form>
          <div className="horizontal-form-row">
            <NumberInput
              label="Incident nr *"
              internalValue={testIncident.incidentNr}
              onValueChange={(value: number) =>
                setTestIncident({ ...testIncident, incidentNr: value })
              }
            />
            <DropDown
              label="Prioriteit"
              onValueChanged={(val: string) =>
                setTestIncident({ ...testIncident, priority: val })
              }
              options={priorityOptions}
              value={testIncident.priority}
            />
          </div>
          <div className="horizontal-form-row">
            <Datepicker
              label="Dtg start *"
              onValueChange={(value?: Date) =>
                setTestIncident({ ...testIncident, dtgStartIncident: value })
              }
              value={testIncident.dtgStartIncident}
            />
            <Datepicker
              label="Dtg eind"
              onValueChange={(value?: Date) =>
                setTestIncident({ ...testIncident, dtgEindeIncident: value })
              }
              value={testIncident.dtgEindeIncident}
            />
          </div>
          <div className="horizontal-form-row">
            <Datepicker
              label="Brw start *"
              onValueChange={(value?: Date) =>
                setTestIncident({ ...testIncident, BrwDtgStart: value })
              }
              value={testIncident.BrwDtgStart}
            />
            <Datepicker
              label="Brw eind"
              onValueChange={(value?: Date) =>
                setTestIncident({ ...testIncident, BrwDtgEinde: value })
              }
              value={testIncident.BrwDtgEinde}
            />
          </div>
          <div className="horizontal-form-row">
            <TextInput
              label="Plaats"
              placeholder="Nieuwe plaats"
              internalValue={testIncident.city}
              onValueChange={(value: string) =>
                setTestIncident({ ...testIncident, city: value })
              }
            />
            <TextInput
              label="Straatnaam"
              placeholder="Nieuwe straatnaam"
              internalValue={testIncident.street}
              onValueChange={(value: string) =>
                setTestIncident({ ...testIncident, street: value })
              }
            />
          </div>
          <div className="horizontal-form-row">
            <div className="horizontal-form-row">
              <TextInput
                label="Postcode"
                placeholder="Nieuwe postcode"
                internalValue={testIncident.postalCode}
                onValueChange={(value: string) =>
                  setTestIncident({ ...testIncident, postalCode: value })
                }
              />
            </div>
            <div className="horizontal-form-row">
              <NumberInput
                label="Huisnummer"
                internalValue={testIncident.houseNumber}
                onValueChange={(value: number) =>
                  setTestIncident({ ...testIncident, houseNumber: value })
                }
              />
              <TextInput
                label="Toevoeging"
                placeholder="A"
                internalValue={testIncident.houseNumberAddition}
                onValueChange={(value: string) =>
                  setTestIncident({
                    ...testIncident,
                    houseNumberAddition: value,
                  })
                }
              />
            </div>
          </div>
          <div className="horizontal-form-row">
            <TextInput
              label="Melding Cl"
              placeholder="Nieuwe melding"
              internalValue={testIncident.brwMeldingCl}
              onValueChange={(value: string) =>
                setTestIncident({ ...testIncident, brwMeldingCl: value })
              }
            />
          </div>
          <div className="horizontal-form-row">
            <TextInput
              label="Melding Cl1"
              placeholder="Nieuwe melding"
              internalValue={testIncident.brwMeldingCl1}
              onValueChange={(value: string) =>
                setTestIncident({ ...testIncident, brwMeldingCl1: value })
              }
            />
            <TextInput
              label="Melding Cl2"
              placeholder="Nieuwe melding"
              internalValue={testIncident.brwMeldingCl2}
              onValueChange={(value: string) =>
                setTestIncident({ ...testIncident, brwMeldingCl2: value })
              }
            />
          </div>
        </form>
      </div>

      <div className="form-container">
        <form>
          {testIncident.testEenheden.map((key, index) => (
            <div key={index} className="horizontal-form-row">
              <TextInput
                label="Naam kazerne"
                placeholder="Naam kazerne"
                internalValue={testIncident.testEenheden[index].kazNaam}
                onValueChange={(value: string) => {
                  testIncident.testEenheden[index].kazNaam = value;
                  setTestIncident({ ...testIncident });
                }}
              />
              <TextInput
                label="Naam voertuig"
                placeholder="Naam voertuig"
                internalValue={testIncident.testEenheden[index].naamVoertuig}
                onValueChange={(value: string) => {
                  testIncident.testEenheden[index].naamVoertuig = value;
                  setTestIncident({ ...testIncident });
                }}
                maxLength={vehicleNameMaxLength}
              />
              {testIncident.testEenheden.length > 1 ? (
                <VariationButton
                  onClickHandler={() =>
                    removeEenheidRow(testIncident.testEenheden[index])
                  }
                  decrement={true}
                />
              ) : (
                ""
              )}
            </div>
          ))}
          <div className="add-eenheden-button">
            <VariationButton onClickHandler={addEenheidRow} decrement={false} />
          </div>
        </form>
      </div>
      <button onClick={onAddIncident} disabled={addBtnDisabled}>
        Incident toevoegen
      </button>
    </div>
  );
}
