import { ChangeEvent } from "react";
import "./style.scss";

type Props = {
  label: string;
  internalValue: number;
  onValueChange: (val: number) => void;
};

export default function NumberInput({
  label,
  internalValue,
  onValueChange,
}: Props) {
  return (
    <div className="number-input-wrapper">
      <label>{label}</label>
      <input
        type="number"
        value={isNaN(internalValue) ? "" : internalValue}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onValueChange(parseFloat(event.currentTarget.value))
        }
      />
    </div>
  );
}
