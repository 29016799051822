import "./style.scss";

export function LoadingComponent({ color, size }: Readonly<{ color?: "primary" | "white", size?: "normal" | "small" }>) {
  return (
    <div className={`lds-grid ${color ?? "primary"} ${size ?? "normal"}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}