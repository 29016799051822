import MainLayout from "./layout/MainLayout";
import StatusPage from "./pages/StatusPage";
import { HomePage } from "./pages/HomePage";
import MeldingenPage from "./pages/MeldingenPage";
import IncidentManagement from "./pages/IncidentManagement";
import IncidentTest from "./pages/IncidentTest";
import SystemLogsPage from "./pages/SystemLogsPage";
import Unauthorized from "./pages/Invalid/Unauthorized";
import { NotFound } from "./pages/Invalid/NotFound";
import { OefenincidentenAddPage } from "./pages/Oefenincidenten/Add";
import { ROLE } from "./enums/role";
import ProtectedRoute from "./models/ProtectedRoute";
import { EenhedenPage } from "./pages/EenhedenPage";
import { OefenincidentenOverviewPage } from "./pages/Oefenincidenten/Overview";
import { EditOefenincidentPage } from "./pages/Oefenincidenten/Edit";
import { MeldkamerPage } from "./pages/Oefenincidenten/Kladblokregels";

export const enum Routes {
  OefenincidentOverview = "/oefenincident",
  OefenincidentAdd = "/oefenincident/add",
  OefenincidentEdit = "/oefenincident/edit/:incidentNr",
}

const protectedRoutes: ProtectedRoute[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        id: "Home",
        element: <HomePage />,
      },
      {
        path: "status",
        id: "Status",
        element: <StatusPage />,
        allowedRoles: [ROLE.Administrator]
      },
      {
        path: "meldingen",
        id: "Meldingen",
        element: <MeldingenPage />,
        allowedRoles: [ROLE.Administrator]
      },
      {
        path: "beheer",
        id: "Incidenten beheer",
        element: <IncidentManagement />,
        allowedRoles: [ROLE.Administrator]
      },
      {
        path: "testincident",
        id: "Testincidenten",
        element: <IncidentTest />,
        allowedRoles: [ROLE.Administrator]
      },
      {
        path: "oefenincident",
        id: "Oefenincidenten",
        element: <OefenincidentenOverviewPage />,
        allowedRoles: [ROLE.Administrator, ROLE.Vakbekwaamheid],
        children: [{
          path: "oefenincident/add",
          id: "OefenincidentenAdd",
          element: <OefenincidentenAddPage />,
          allowedRoles: [ROLE.Administrator, ROLE.Vakbekwaamheid]
        },
        {
          path: "oefenincident/edit/:incidentNr",
          id: "OefenincidentenEdit",
          element: <EditOefenincidentPage />,
          allowedRoles: [ROLE.Administrator, ROLE.Vakbekwaamheid]
        },
        {
          path: "oefenincident/meldkamer/:incidentNr",
          id: "OefenincidentenMeldkamer",
          element: <MeldkamerPage />,
          allowedRoles: [ROLE.Administrator, ROLE.Vakbekwaamheid]
        }]
      },
      {
        path: "eenheden",
        id: "Eenheden",
        element: <EenhedenPage />,
        allowedRoles: [ROLE.Administrator],
      },
      {
        path: "logs",
        id: "Systeem Logs",
        element: <SystemLogsPage />,
        allowedRoles: [ROLE.Administrator]
      }
    ],
  },
  {
    path: "unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default protectedRoutes;
