import {ChangeEvent} from "react";
import "./style.scss";

type Props = {
  label?: string;
  internalValue?: string;
  placeholder?: string;
  maxLength?: number;
  onValueChange?: (val: string) => void;
  disabled?: boolean;
}

/**
 * TextArea component
 * @param label Label used above the input
 * @param internalValue The value of the input
 * @param onValueChange Function to call when the value of the input changes
 * @param placeholder Placeholder text
 * @param maxLength Maximum length of the input
 * @param disabled Whether the input is disabled
 * @constructor
 */
export default function TextArea({
  label,
  internalValue,
  onValueChange,
  placeholder,
  maxLength,
  disabled,
}: Readonly<Props>) {
  return (
    <div className="text-area-wrapper">
      {label && <label>{label}</label>}
      <textarea
        placeholder={placeholder}
        value={internalValue}
        rows={4}
        disabled={disabled ?? false}
        maxLength={maxLength}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>{
          if(onValueChange)
            onValueChange(event.currentTarget.value);
        }}
      />
    </div>
  );
}