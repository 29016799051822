import style from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

/**
 * A cancel button with an x icon
 * @param onClick - The function to call when the button is clicked
 * @constructor
 */
export function CancelButton({onClick}: Readonly<{onClick: () => void}>) {
  return(
    <button className={style.cancelButton} onClick={onClick}>
      <FontAwesomeIcon icon={faXmark} />
    </button>
  );
}
