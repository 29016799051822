import { AuthError, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { loginRequest } from "../../../config";

interface UnathorizedProps {
  requestedLocation?: string;
}

export default function Unauthorized({ requestedLocation }: UnathorizedProps) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();

  if (isAuthenticated) return <Navigate to={requestedLocation || "status"} />;

  if (accounts.length > 0) {
    return <Navigate to={requestedLocation || "status"} />;
  }

  if (inProgress === "login") {
    return <p>Logging in...</p>;
  }

  if (inProgress === "handleRedirect") {
    return <p>Handling redirect...</p>;
  }

  if (inProgress === "ssoSilent") {
    instance.loginRedirect(loginRequest);
  }

  if (!isAuthenticated && inProgress === "none") {
    instance.loginRedirect(loginRequest);
  }

  return <p>Unauthorized you will be redirected...</p>;
}
