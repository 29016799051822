import { Configuration, RedirectRequest } from "@azure/msal-browser";

/**
 * Global MSAL configuration
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: DetermineClientId(),
    authority:
      "https://login.microsoftonline.com/20316ca8-6512-41f7-9734-485fb756949b",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Get's the corresponding api url based on the environment
 * @constructor
 */
export function DetermineApiUri(): string {
  // Local development uses it's own app registration for local return url's
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_BACKEND_URL ?? "https://localhost:5003";
  }

  const hostname = window.location.hostname;
  if (hostname.includes("test") || hostname.includes("as-dev-oiv-beheer-test"))
    return "https://as-dev-oiv-beheer-api-test.azurewebsites.net";
  if (hostname.includes("acc")  || hostname.includes("as-dev-oiv-beheer"))
    return "https://as-dev-oiv-beheer-api.azurewebsites.net";
  else if (hostname.includes("staging") || hostname.includes("as-oiv-beheer-staging"))
    return "https://as-oiv-beheer-api-staging.azurewebsites.net";

  // Default the client id of the test environment will be used
  return "https://as-oiv-beheer-api.azurewebsites.net";
}

// Determine the client id for authentication.
export function DetermineClientId(): string {
  // Local development uses it's own app registration for local return url's
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || window.location.hostname.includes("localhost")) {
    return process.env.REACT_APP_CLIENT_ID ?? "6b63322b-685a-406c-805c-ea893dc4cee8";
  }

  const hostname = window.location.hostname;
  if (hostname.includes("test") || hostname.includes("as-dev-oiv-beheer-test"))
    return "6b63322b-685a-406c-805c-ea893dc4cee8";
  if (hostname.includes("acc") || hostname.includes("as-dev-oiv-beheer"))
    return "a7f6e042-5e95-43dd-96c1-362027c7808d";
  else if (hostname.includes("staging") || hostname.includes("as-oiv-beheer-staging"))
    return "b3a107b8-d64c-4c52-bf37-9f78850d78b6";

  return "807b7f61-26e3-4297-89f9-70b62b1ff7d1";
}


// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["user.read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const backendRequest = {
  scopes: ["api://423c1fba-c901-4d70-a7a9-957ead1e35d0/access_as_user"],
};
