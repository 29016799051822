import { EenhedenGroep } from "../../models/EenhedenGroep";
import { useState } from "react";
import style from "./style.module.scss";
import TextInput from "../../components/UI/InputElements/TextInput";
import { DetermineApiUri } from "../../../config";
import { useProtectedFetch } from "../../hooks/useProtectedFetch";
import Select from "react-select";
import { SelectOption } from "../../models/SelectOption";
import { EditButton } from "../../components/IconButtons/EditButton";
import { RemoveButton } from "../../components/IconButtons/RemoveButton";
import { CancelButton } from "../../components/IconButtons/CancelButton";
import { SubmitButton } from "../../components/IconButtons/SubmitButton";
import { toast } from "react-toastify";
import { defaultToastOptions } from "../../utils/defaultToastOptions";

/**
 * Form for editing an eenheidsgroep
 * @param eenhedenGroep The eenheidsgroep to edit
 * @param eenhedenOptions The options for the eenheden
 * @param onChange The function to call when the form is changed
 * @constructor
 */
export function EenheidGroepForm({ eenhedenGroep, eenhedenOptions, onChange }: Readonly<{
  eenhedenGroep: EenhedenGroep,
  eenhedenOptions: { value: string, label: string }[],
  onChange: () => void
}>) {
  const request = useProtectedFetch();
  const [eenhedenGroepState, setEenhedenGroepState] = useState(eenhedenGroep);
  const [isEditing, setIsEditing] = useState(eenhedenGroep.isNew ?? false);

  /**
   * Enable the edit mode
   */
  function enableEdit() {
    setIsEditing(true);
  }

  /**
   * Submit the form
   */
  async function onSubmit() {
    try {
      await request(`${DetermineApiUri()}/api/eenhedengroup`, {
        method: eenhedenGroepState.isNew ? "PUT" : "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eenhedenGroepState),
      });
      toast.success("Eenheidsgroep aangemaakt.", defaultToastOptions);
      setIsEditing(false);
      onChange();
    } catch (error) {
      toast.error(
        "Er is iets fout gegaan met het toevoegen",
        defaultToastOptions
      );
    }
  }

  /**
   * Cancel the edit mode
   */
  function onCancel() {
    setIsEditing(false);
    setEenhedenGroepState(eenhedenGroep);
    onChange();
  }

  /**
   * Remove the eenheidsgroep
   */
  async function onRemove() {
    try {
      await request(`${DetermineApiUri()}/api/eenhedengroup?id=${eenhedenGroepState.id}`, {
        method: "DELETE",
      });
      onChange();
      toast.success("Eenheidsgroep verwijderd.", defaultToastOptions);
    } catch (error) {
      toast.error(
        "Er is iets fout gegaan met het toevoegen",
        defaultToastOptions
      );
    }
  }

  /**
   * Render the form
   */
  if (!isEditing) {
    return (
      <div className={style.eenheidGroupRow}>
        <div>{eenhedenGroepState.name}</div>
        <div>{eenhedenGroepState.description}</div>
        <div className={style.vehicleCodes}>
          {eenhedenGroepState.vehicleCodes.map(v => <span key={v} className={style.vehicleCode}>{v}</span>)}
        </div>
        <div className={style.editActions}>
          <EditButton onClick={() => enableEdit()} />
          <RemoveButton onClick={() => onRemove()} />
        </div>
      </div>
    );
  }

  return (
    <div className={style.eenheidGroupRow}>
      <TextInput
        placeholder={"Vul hier de groepsnaam in.."}
        internalValue={eenhedenGroepState.name}
        onValueChange={(val) => setEenhedenGroepState({ ...eenhedenGroepState, name: val })} />
      <TextInput
        placeholder={"Vul hier de beschrijving in.."}
        internalValue={eenhedenGroepState.description}
        onValueChange={(val) => setEenhedenGroepState({ ...eenhedenGroepState, description: val })} />
      <Select
        className={"search-select-container"}
        classNamePrefix="search-select"
        placeholder={"Type hier een roepnummer..."}
        options={eenhedenOptions}
        onChange={(value: unknown) => {
          const mapped = (value as SelectOption[]).map(x => x.value);
          setEenhedenGroepState({ ...eenhedenGroepState, vehicleCodes: mapped });
        }}
        isMulti={true}
        defaultValue={eenhedenOptions.filter(x => eenhedenGroepState.vehicleCodes.includes(x.value))}
        hideSelectedOptions={true}
        isSearchable={true} />
      <div className={style.editActions}>
        <CancelButton onClick={onCancel} />
        <SubmitButton onClick={onSubmit} />
      </div>
    </div>
  );
}
