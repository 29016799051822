import { useMatch, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { NotFound } from "../../Invalid/NotFound";
import { KladblokRegel } from "../../../models/Oefenincident";
import { DetermineApiUri } from "../../../../config";
import { useProtectedFetch } from "../../../hooks/useProtectedFetch";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { ToastContainer } from "react-toastify";
import style from "./style.module.scss";
import { DisciplineIndicator } from "../../../components/DisciplineIndicator";
import TextInput from "../../../components/UI/InputElements/TextInput";
import { SendButton } from "../../../components/IconButtons/SendButton";
import React, { useEffect, useState, } from "react";
import { KladblokImage } from "./components/KladblokImage";
import { EditButton } from "../../../components/IconButtons/EditButton";
import Checkbox from "../../../components/UI/InputElements/Checkbox";

/**
 * Page that simulates actions of the meldkamer, used by users that are running an oefenincident.
 * @returns 
 */
export function MeldkamerPage() {
  const protectedFetch = useProtectedFetch("none");
  const navigate = useNavigate();
  const match = useMatch("oefenincident/meldkamer/:incidentNr");
  const { incidentNr } = match?.params as { incidentNr: string };
  const { data: kladblokregels, mutate } = useSWR<KladblokRegel[]>(incidentNr ? `${DetermineApiUri()}/api/oefenincident/${incidentNr}/kladblok` : null, {
    fetcher: useProtectedFetch(),
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateOnMount: true,
  });
  const [newKladblokregel, setNewKladblokregel] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const kladblokWrapperRef = React.useRef<HTMLDivElement>(null);

  const [isPolitie, setIsPolitie] = useState<boolean>(false);
  const [isBrandweer, setIsBrandweer] = useState<boolean>(true);
  const [isAmbulance, setIsAmbulance] = useState<boolean>(false);

  /**
   * Navigate to the edit page
   */
  async function onEdit() {
    navigate(`/oefenincident/edit/${incidentNr}`);
  }

  /**
   * Post a new kladblokregel
   */
  async function postAsync() {
    if (!newKladblokregel)
      return;

    setIsLoading(true);

    const regel: KladblokRegel = {
      userName: "Meldkamer",
      inhoudKladblokRegel: newKladblokregel,
      tIndDiscKladblokRegel: `${isPolitie ? "P" : "-"}${isBrandweer ? "B" : "-"}${isAmbulance ? "A" : "-"}`
    };

    await protectedFetch(`${DetermineApiUri()}/api/oefenincident/${incidentNr}/kladblok`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(regel)
    });

    setIsLoading(false);
    mutate();
    setNewKladblokregel("");
  }

  /**
   * Scroll to the bottom of the kladblok when new messages are added
   */
  useEffect(() => {
    if (kladblokWrapperRef.current) {
      kladblokWrapperRef.current.scrollTop = kladblokWrapperRef.current.scrollHeight;
    }
  }, [kladblokregels]);

  if (!incidentNr)
    return <NotFound />;

  if (!kladblokregels)
    return <LoadingComponent />;

  return (
    <div className={`content-container ${style.contentContainer}`}>
      <ToastContainer />
      <div className={style.header}>
        <h1>Tegenspel oefenincidenten: {incidentNr}</h1>
        <EditButton onClick={() => onEdit()}></EditButton>
      </div>
      <p>Hier kunt u tegenspel bieden tijdens een oefenincident. Wanneer u kladblokregels verstuurt zal dit gebeuren onder de noemer {"\"Meldkamer\""}. De relevante disciplines kunt u wijzigen per regel.</p>
      <p></p>
      <hr />
      <div ref={kladblokWrapperRef} className={style.kladblokWrapper}>
        {kladblokregels.map((kladblokregel) => (
          <div key={kladblokregel.kladblokRegelId} className={`${style.regel} ${kladblokregel.userName === "Meldkamer" ? style.own : ""}`}>
            <div className={style.disciplineIndicator}><DisciplineIndicator
              politie={kladblokregel?.tIndDiscKladblokRegel?.includes("P") ?? false}
              brandweer={kladblokregel?.tIndDiscKladblokRegel?.includes("B") ?? false}
              ambulance={kladblokregel?.tIndDiscKladblokRegel?.includes("A") ?? false}
            /></div>
            <div className={`${style.inhoud} `}>
              {kladblokregel.userName !== "Meldkamer" && <span className={style.gebruiker}>{kladblokregel.userName}</span>}
              <span className={style.date}>{new Date(kladblokregel.dtgKladblokRegel ?? new Date).toLocaleDateString("nl-NL", {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
              })}</span>
              {(kladblokregel.mediaPath && kladblokregel.fileGuid) && <KladblokImage incidentNr={incidentNr} fileGuid={kladblokregel.fileGuid} />}
              <span>{kladblokregel.inhoudKladblokRegel}</span>
            </div>
          </div>
        ))}
        <div id={style.anchor}></div>
      </div>
      <p className={style.explanation}>Geef hieronder aan voor welk discipline de kladblokregel bestemd is.</p>
      <div className={style.multiCheckboxWrapper}>
        <Checkbox label="Politie" internalValue={isPolitie} onValueChange={setIsPolitie} />
        <Checkbox label="Brandweer" internalValue={isBrandweer} onValueChange={setIsBrandweer} />
        <Checkbox label="Ambulance" internalValue={isAmbulance} onValueChange={setIsAmbulance} />
      </div>
      <div className={style.inputWrapper}>
        <TextInput placeholder="Type een kladblokregel hier..." internalValue={newKladblokregel} onValueChange={setNewKladblokregel} />
        <SendButton onClick={postAsync} isLoading={isLoading} />
      </div>
    </div>
  );
}