import useSWR from "swr";
import { VehicleStatus } from "../models/VehicleStatus";
import { DetermineApiUri } from "../../config";
import { useProtectedFetch } from "./useProtectedFetch";

/**
 * Hook for fetching the vehicles from the OIV backend
 */
export function useEenheden() {
  const { data: eenheden } = useSWR<VehicleStatus[]>(`${DetermineApiUri()}/api/eenheden`, {
    fetcher: useProtectedFetch(),
  });

  /**
   * Create a list of select options
   * @type {{value: string, label: string}[]}
   */
  const selectOptions = eenheden?.filter(x => x.roepnaamEenheid)
    .map((eenheid) => ({ value: eenheid.roepnaamEenheid, label: eenheid.roepnaamEenheid }))
    .filter((item, index, self) => self.findIndex(i => i.value === item.value) === index)
    .sort((a, b) => a.label.localeCompare(b.label));

  return {
    eenheden,
    selectOptions
  };
}
