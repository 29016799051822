import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

type Props = {
  decrement: boolean;
  onClickHandler: () => void;
  className?: string;
};

export default function VariationButton({ decrement, onClickHandler,className }: Readonly<Props>) {
  return (
    <div className="variation-button-wrapper ">
      <button type="button" className={className} onClick={onClickHandler}>
        <FontAwesomeIcon icon={decrement ? faMinus : faPlus} />
      </button>
    </div>
  );
}
