import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ReactNode, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Unauthorized from "../../pages/Invalid/Unauthorized";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard = ({ children }: AuthGuardProps) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>();

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Unauthorized requestedLocation={requestedLocation ?? "status"} />;
  }

  if (!instance.getActiveAccount()) {
    // Set active account to first of accounts.
    instance.setActiveAccount(accounts[0]);
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
