import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AddIncident from "../../components/add-incident";
import "./style.scss";

export default function IncidentTest() {
  return (
    <div className="incident-test-wrapper">
      <ToastContainer />
      <AddIncident />
    </div>
  );
}
