import Moment from "moment";
import { Incident } from "../../models/Incident";
import "./IncidentOverview.scss";

type Props = {
  incidents: Incident[];
};

function IncidentOverview({ incidents }: Props) {
  return (
    <div className="incident-overview">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th style={{ width: "65px" }}>
              Regio{" "}
              <span className="tooltip">
                &#9432;
                <span className="tooltiptext">
                  Is het een zeeuws, mwb of gecombineerd incident
                </span>
              </span>
            </th>
            <th>Prio</th>
            <th>Plaatsnaam</th>
            <th>Locatie</th>
            <th style={{ width: "110px" }}>
              Import delta{" "}
              <span className="tooltip">
                &#9432;
                <span className="tooltiptext">
                  Het verschil in seconden tussen start brw incident en import
                  vanuit de replica database
                </span>
              </span>
            </th>
            <th>
              Start{" "}
              <span className="tooltip">
                &#9432;
                <span className="tooltiptext">
                  Start tijdstip van incident in GMS
                </span>
              </span>
            </th>
            <th>
              Start BRW{" "}
              <span className="tooltip">
                &#9432;
                <span className="tooltiptext">
                  Start tijdstip van incident in GMS voor brandweer
                </span>
              </span>
            </th>
            <th>Einde BRW</th>
            <th>Einde</th>
            <th>
              Import{" "}
              <span className="tooltip">
                &#9432;
                <span className="tooltiptext">
                  Tijdstip wanneer het incident is geimporteerd vanuit de
                  replica database
                </span>
              </span>
            </th>
            <th>
              Update{" "}
              <span className="tooltip">
                &#9432;
                <span className="tooltiptext">
                  Tijdstip wanneer het incident voor het laatst is bijgewerkt
                  vanuit de replica database
                </span>
              </span>
            </th>
            <th style={{ width: "75px" }}>
              <span className="tooltip">
                &#9432;
                <span className="tooltiptext">WGS84 Coordinaten</span>
              </span>{" "}
              WGS84
            </th>
          </tr>
        </thead>
        <tbody>
          {incidents
            ? incidents.map((incident: Incident) => {
              return (
                <tr key={incident.incidentId}>
                  <td>{incident.incidentId}</td>
                  <td>{incident.region}</td>
                  <td>{incident.prioriteitBrandweer}</td>
                  <td>{incident.plaatsNaam}</td>
                  <td>{incident.naamLocatie}</td>
                  <td>
                    {Moment.utc(incident.createdAtUtcByVrmwb)
                      ?.local()
                      .diff(incident.brwDtgStartIncident, "seconds")}
                      s
                  </td>
                  <td>
                    {Moment(incident.dtgStartIncident)?.format(
                      "DD/MM/YYYY, HH:mm:ss"
                    )}
                  </td>
                  <td>
                    {Moment(incident.brwDtgStartIncident)?.format(
                      "DD/MM/YYYY, HH:mm:ss"
                    )}
                  </td>
                  <td>
                    {Moment(incident.brwDtgEindeIncident)?.format(
                      "DD/MM/YYYY, HH:mm:ss"
                    )}
                  </td>
                  <td>
                    {Moment(incident.dtgEindeIncident)?.format(
                      "DD/MM/YYYY, HH:mm:ss"
                    )}
                  </td>
                  <td>
                    {Moment.utc(incident.createdAtUtcByVrmwb)
                      ?.local()
                      .format("DD/MM/YYYY, HH:mm:ss")}
                  </td>
                  <td>
                    {Moment.utc(incident.updatedAtUtcByVrmwb)
                      ?.local()
                      .format("DD/MM/YYYY, HH:mm:ss")}
                  </td>
                  <td>{incident.txCoordLocWgs84 ? "Ja" : "Nee"}</td>
                </tr>
              );
            })
            : ""}
        </tbody>
      </table>
    </div>
  );
}

export default IncidentOverview;
