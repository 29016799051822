import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { OivNotification } from "../../../models/Notification";
import "./style.scss";

interface NotificationViewProps {
  notification: OivNotification;
}

export const NotificationView = ({ notification }: NotificationViewProps) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: notification.notificationNode,
    editable: false,
  });

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="notification-view">
        <EditorContent editor={editor} />
      </div>
    </>
  );
};
