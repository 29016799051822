import style from "./style.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";

/**
 * A button with a reactivate icon
 * @param onClick - The function to call when the button is clicked
 */
export function ReactivateButton({ onClick }: Readonly<{ onClick: () => void }>) {
  return (
    <button className={style.reactivateButton} onClick={onClick}>
      <FontAwesomeIcon icon={faRotateLeft} />
    </button>
  );
}
