import { ToastOptions } from "react-toastify";

/**
 * Default toast options used for styling
 */
export const defaultToastOptions: ToastOptions = {
  position: "top-right",
  closeOnClick: true,
  theme: "dark",
};
