import {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import routes from "../../routes";
import {classNames} from "../../utils/classNames";
import {useUserRole} from "../../hooks/useUserRole";
import {ROLE} from "../../enums/role";
import ProtectedRoute from "../../models/ProtectedRoute";

export default function Navbar() {
  const location = useLocation();
  const mainRoutes = routes.find((route) => route.path === "/")?.children;
  const userRole = useUserRole();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className="nav">
      <div className="nav-main">
        <div className="nav-contents">
          <div className="nav-mobile">
            {/* Mobile menu button */}
            <button
              className="nav-mobile-button"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span>Open main menu</span>

              {!mobileMenuOpen ? (
                //Icon when menu is closed. Heroicon: outline/bars-3
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              ) : (
                //Icon when menu is open. Heroicon: outline/x-mark
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="nav-items">
            <Link to={"/"} className="logo">
              <img src="/logovrmwb.png" alt="logo"/>
            </Link>
            <div className="nav-links">
              <div className="nav-links-container">
                {mainRoutes &&
                  mainRoutes
                    .filter(x => x.allowedRoles?.includes(userRole as ROLE))
                    .map((route: ProtectedRoute) => {
                      if (route.path)
                        return (
                          <Link key={route.path} className={classNames(
                            location.pathname == `/${route.path}`
                              ? "active"
                              : "hover",
                            "nav-link"
                          )} to={route.path} aria-current={
                            location.pathname == `/${route.path}`
                              ? "page"
                              : undefined
                          }>
                            {route.id}
                          </Link>
                        );
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="nav-mobile-menu">
          <div className="nav-mobile-container">
            {mainRoutes &&
              mainRoutes
                .filter(x => x.allowedRoles?.includes(userRole as ROLE))
                .map((route: ProtectedRoute) => {
                  if (route.path)
                    return (
                      <Link
                        key={route.path}
                        className={classNames(
                          location.pathname == `/${route.path}`
                            ? "active"
                            : "hover",
                          "nav-mobile-link"
                        )}
                        to={route.path}
                        aria-current={
                          location.pathname == `/${route.path}`
                            ? "page"
                            : undefined
                        }
                      >
                        {route.id}
                      </Link>
                    );
                })}
          </div>
        </div>
      )}
    </nav>
  );
}
