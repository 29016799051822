import { DetermineApiUri } from "../../config";
import useSWR from "swr";
import { useProtectedFetch } from "./useProtectedFetch";
import { OivNotification } from "../models/Notification";

export function useNotifications() {
  const { data, error } = useSWR<OivNotification[]>(
    `${DetermineApiUri()}/api/notification`,
    useProtectedFetch(),
    {}
  );

  return {
    notifications: data,
    isLoading: !error && !data,
    isError: error,
  };
}
